import React, {FC, useEffect, useState} from "react";
import {NavLink, Outlet} from "react-router-dom";
import BlockImg from "../../../images/blocks_bg.png";
import WaveLight from "../../../images/waves-light.svg";
import {useDarkMode, useLocalStorage} from "usehooks-ts";
import useWallet from '../../../common/hooks/useWallet';

interface ListingLayoutData {
}

const ProfileLayout: FC<ListingLayoutData> = (props) => {
    const { isWalletConnected, addressHash, connect, disconnect } = useWallet();
    const [bgImageIndex, setBgImageIndex] = useState<number>(1);
    const [bgGradient, setBgGradient] = useState<string>('');
    const {isDarkMode, toggle} = useDarkMode();
    const [value, setValue] = useLocalStorage("isDarkMode", isDarkMode ? "dark" : "light");

    const backgroundImages: { [key: number]: string } = {
        1: BlockImg,
        2: WaveLight,
        3: BlockImg,
    };

    const getBackgroundImage = (index: number): string => backgroundImages[index] || BlockImg as string;

    const getBackgroundGradient = (mode: string): string => {
        if (mode === "dark") {
            return process.env.REACT_APP_BACKGROUND_DARK || "45deg, #111111, #1a1a1a, #232323, #2c2c2c, #363636";
        } else if (mode === "dim") {
            return process.env.REACT_APP_BACKGROUND_DIM || "45deg, #111a2e, #14223d, #19294d, #1d3160, #233a75";
        } else {
            return process.env.REACT_APP_BACKGROUND_GRADIENT || "45deg, #081d35, #102a55, #193877, #224799";
        }
    };

    useEffect(() => {
        const parsedBgImageIndex = parseInt(process.env.REACT_APP_BACKGROUND_IMAGE_INDEX || '1', 10);
        setBgImageIndex(parsedBgImageIndex);
        const gradient = getBackgroundGradient(value);
        setBgGradient(gradient);
    }, [value]);

     const handleSignOut = () => {
        disconnect();
    };

    return (
        <div className="bs-body bs-profile">
            <section>
                <div className="bs-banner" style={{backgroundImage: `url(${getBackgroundImage(bgImageIndex)}), linear-gradient(${bgGradient})`}}>
                    <div className="container">
                        <div className="d-flex flex-wrap justify-content-between align-items-center gap-4">
                            <div>
                                <NavLink className="small text-white opacity-75" to="/">
                                    <i className="bi bi-arrow-left"></i> Back Home</NavLink>
                                <h1 className="h5 text-white my-1">Account Overview</h1></div>
                            <div className="d-flex gap-3">
                                <a className="btn btn-light" onClick={handleSignOut}><i className="bi bi-box-arrow-right"></i> Sign Out</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bs-profile-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-xl-3 mb-4 mb-lg-0">
                            <div className="bs-card p-3">
                                <div className="d-flex flex-wrap justify-content-between align-items-center gap-3">
                                    <div className="text-break">
                                        <div className="fw-medium fs-5">
                                            OpenScan.ai Account
                                        </div>
                                        <div className="text-muted">
                                            support@blocksscan.io
                                        </div>
                                    </div>
                                    <div className="d-block d-lg-none mb-2">
                                        <button className="btn border" type="button" data-bs-toggle="collapse" data-bs-target="#asideNav" aria-controls="asideNav"
                                                aria-expanded="false" aria-label="Toggle navigation">
                                            <i className="bi bi-list me-1"></i>Sidebar Menu
                                        </button>
                                    </div>
                                </div>

                                <nav className="navbar-expand-lg position-sticky" style={{top: '4.5rem'}}>
                                    <div className="collapse navbar-collapse flex-column" id="asideNav">
                                        <hr className="w-100 mb-1 mt-2"/>
                                        <ul id="section-chart" className="nav nav-pills nav-pills-flush flex-column w-100 gap-1">
                                            <li className="nav-item fw-medium text-uppercase small px-3 mt-4 mb-1">
                                                Account
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/myaccount"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Account
                                                    Overview</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/mysettings"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Account
                                                    Settings</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/my-public-profile"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Public
                                                    Profile</NavLink>
                                            </li>

                                            <li className="nav-item fw-medium text-uppercase small px-3 mt-4 mb-1">
                                                Lists
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/myaddress"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Watch
                                                    List</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/mynotes_address"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Private
                                                    Name Tags</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/mynotes_tx"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Txn Private
                                                    Notes</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/mytokenignore"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Custom
                                                    Ignore List</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/myadvancedfilters"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Advanced
                                                    Filter</NavLink>
                                            </li>

                                            <li className="nav-item fw-medium text-uppercase small px-3 mt-4 mb-1">
                                                Others
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/myapikey"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>API
                                                    Keys</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/myverify_address"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Verified
                                                    Addresses</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/mycustomabi"
                                                         className={({isActive}) => (isActive ? 'nav-link fw-medium py-2 px-3 active' : 'nav-link fw-medium py-2 px-3')}>Custom
                                                    ABIs</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className="col-lg-8 col-xl-9">
                            <Outlet/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ProfileLayout;
