import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import JazziconAvatar from "../../../../components/b-avatar";
import { profileAccountService } from "../../../../store/actions"; 
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useWallet from '../../../../common/hooks/useWallet';

const WalletButton: React.FC = () => {
  const { isWalletConnected, addressHash, connect, disconnect } = useWallet();
  const [signerAddress, setSignerAddress] = useState<string>("");
  const [signature, setSignature] = useState<string>("");
  const [verified, setVerified] = useState<boolean>(false);
  const [showVerificationModal, setShowVerificationModal] = useState<boolean>(false);
  const [verificationTimestamp, setVerificationTimestamp] = useState<string | null>(null);

  useEffect(() => {
    const checkIfAlreadyVerified = () => {
      const isVerified = localStorage.getItem('isVerified') === 'true';
      const timestamp = localStorage.getItem('verificationTimestamp');
      setVerified(isVerified);
      setVerificationTimestamp(timestamp);
    };
    checkIfAlreadyVerified();
  }, []);

  const signMessage = async (signer: ethers.Signer) => {
    try {
      const timestamp = new Date().toISOString();
      const message = `Hello, please sign this message to verify your identity! Timestamp: ${timestamp}`;
      const signature = await signer.signMessage(message);
      setSignature(signature);
      return { message, signature };
    } catch (error) {
      console.error("Failed to sign message:", error);
      alert("Failed to sign message. Please try again.");
      return null;
    }
  };

  const verifySignature = async (signerAddress: string, signatureData: { message: string, signature: string }) => {
    try {
      const { message, signature } = signatureData;
      const messageHash = ethers.hashMessage(message);
      console.log("Message Hash:", messageHash);

      const recoveredAddress = ethers.recoverAddress(messageHash, signature);
      console.log("Recovered Address:", recoveredAddress);
      console.log("Signer Address:", signerAddress);

      if (recoveredAddress.toLowerCase() === signerAddress.toLowerCase()) {
        setVerified(true);
        setShowVerificationModal(false);

        const timestamp = new Date().toISOString();
        setVerificationTimestamp(timestamp);

        localStorage.setItem('isVerified', 'true');
        localStorage.setItem('verificationTimestamp', timestamp);

        const data = { message, signature };

        try {
          const response = await profileAccountService.getVerified(data);
          console.log("API response:", response);
          return true; 
        } catch (error) {
          console.error("API call failed:", error);
          alert("Failed to verify signature with the server.");
          return false;
        }
      } else {
        setVerified(false);
        alert("Signature is invalid.");
        return false;
      }
    } catch (error) {
      console.error("Failed to verify signature:", error);
      alert("Failed to verify signature. Please try again.");
      return false;
    }
  };

  const handleSignOut = () => {
    disconnect();
  };

  useEffect(() => {
    const autoVerify = async () => {
      if (signerAddress && !verified && showVerificationModal) {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const signatureData = await signMessage(signer);
        if (signatureData) {
          const isVerified = await verifySignature(signerAddress, signatureData);
          if (isVerified) {
            setShowVerificationModal(false); 
          }
        }
      }
    };
    autoVerify();
  }, [signerAddress, showVerificationModal]);

  return (
    <ConnectButton.Custom>
      {({
        account,
        openAccountModal,
        openConnectModal,
        mounted,
      }) => {
        const ready = mounted;
        const connected = ready && account;

       /* eslint-disable react-hooks/rules-of-hooks */
        useEffect(() => {
          if (connected && !verified) {
            setShowVerificationModal(true);
          }
        }, [connected]);
        /* eslint-enable react-hooks/rules-of-hooks */
        
        return (
          <React.Fragment>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-sm nav-item bs-sign-btn"
                onClick={async () => {
                  if (!connected) {
                    openConnectModal();  
                  } else if (verified) {
                    openAccountModal(); 
                  }
                }}
                disabled={!ready}
              >
                {connected ? (
                  verified ? (
                    <div className="d-flex align-items-center gap-2">
                      {account.ensAvatar ? (
                        <img
                          src={account.ensAvatar}
                          alt={account.displayName}
                          style={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            marginRight: 8,
                          }}
                        />
                      ) : (
                        <JazziconAvatar address={account.address} diameter={20} />
                      )}
                      <span>{account.displayName}</span>
                    </div>
                  ) : (
                    <span>Verify Account</span>
                  )
                ) : (
                  <React.Fragment>
                    <i className="bi bi-person-circle" style={{ padding: '5px' }}></i>
                    Sign In
                  </React.Fragment>
                )}
              </button>

              <Modal show={showVerificationModal} onHide={() => setShowVerificationModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Verify Your Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>To finish connecting, you must sign a message in your wallet to verify that you are the owner of this account.</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button 
                    variant="primary"
                    onClick={async () => {
                      const provider = new ethers.BrowserProvider(window.ethereum);
                      const signer = await provider.getSigner();
                      const address = await signer.getAddress();
                      setSignerAddress(address);
                      setShowVerificationModal(false); 
                    }}
                  >
                    Sign Message
                  </Button>
                  <Button variant="secondary" onClick={() => setShowVerificationModal(false)}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>

            {isWalletConnected && (
              <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="/myaccount">My Profile&nbsp;</a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item" href="/myaddress" >Watch List&nbsp;</a>
              </li>
              <li>
                <a className="dropdown-item" href="/mynotes_tx">Txn Private Notes&nbsp;</a>
              </li>
              <li>
                <a className="dropdown-item" href="/mynotes_address">Private Name Tags&nbsp;</a>
              </li>
              <li>
                <a className="dropdown-item" href="/mytokenignore">Token Ignore List&nbsp;</a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item" href="/myapikey">API Keys&nbsp;</a>
              </li>
              <li>
                <a className="dropdown-item" href="/myverify_address">Verified Address&nbsp;</a>
              </li>
              <li>
                <a className="dropdown-item" href="/mycustomabi">Custom ABI&nbsp;</a>
              </li>
              <li>
                <a className="dropdown-item" href="/myadvancedfilters">Advanced Filter&nbsp;</a>
              </li>
                <li>
                  <a className="btn btn-sm bs-sign-btn d-lg-block py-2 px-4" onClick={handleSignOut}>
                    <i className="bi bi-box-arrow-right me-1"></i>Sign Out&nbsp;
                  </a>
                </li>
              </ul>
            )}
          </React.Fragment>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default WalletButton;