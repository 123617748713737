import React, { FC, ReactNode } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import "./i18n/i18n";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "reactjs-popup/dist/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "@rainbow-me/rainbowkit/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createConfig, WagmiConfig, configureChains } from "wagmi";
import { xdc, mainnet } from "wagmi/chains";
import {
  RainbowKitProvider,
  darkTheme,
  lightTheme,
} from "@rainbow-me/rainbowkit";
import { useDarkMode, useLocalStorage } from "usehooks-ts";
import CustomAvatar from "./components/avatar-custom";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  rabbyWallet,
  metaMaskWallet,
  trustWallet,
  walletConnectWallet,
  phantomWallet,
  okxWallet,
  ledgerWallet,
  coinbaseWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { rainbowWeb3AuthConnector } from "./connectors/rainbowWeb3AuthConnector";
import { publicProvider } from "wagmi/providers/public";

// Set theme color
const setThemeColor = () => {
  const bsTheme: any = {
    "--color-theme": process.env.REACT_APP_BS_THEME || "#081d35",
    "--color-primary": process.env.REACT_APP_BS_PRIMARY || "#34a1ff",
  };
  const root = window.document.documentElement;
  if (root) {
    for (let label in bsTheme) {
      const value = bsTheme[label];
      root.style.setProperty(label, value);
    }
  }
};

// Configure chains and clients
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [xdc, mainnet],
  [publicProvider()]
);

const connector = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      metaMaskWallet({ chains }),
      trustWallet({ chains }),
      rabbyWallet({ chains }),
      walletConnectWallet({ chains }),
      phantomWallet({ chains }),
      okxWallet({ chains }),
      ledgerWallet({ chains }),
    ],
  },
  {
    groupName: "Others",
    //@ts-ignore
    wallets: [rainbowWeb3AuthConnector({ chains })],
  },
]);

const config = createConfig({
  autoConnect: false,
  connectors: connector,
  publicClient,
  webSocketPublicClient,
});

// Dark theme wrapper component
interface DarkThemeWrapperProps {
  children: ReactNode;
}

const DarkThemeWrapper: FC<DarkThemeWrapperProps> = ({ children }) => {
  const { isDarkMode } = useDarkMode();
  const [mode] = useLocalStorage("isDarkMode", isDarkMode ? "dark" : "light");
  const theme = mode === "light" ? lightTheme() : darkTheme();
  return (
    <RainbowKitProvider avatar={CustomAvatar} theme={theme} chains={chains}>
      {children}
    </RainbowKitProvider>
  );
};

const queryClient = new QueryClient();

setThemeColor();
const root = ReactDOM.createRoot(
  document.getElementById("bs-root") as HTMLElement
);
root.render(
  <WagmiConfig config={config}>
    <QueryClientProvider client={queryClient}>
      <DarkThemeWrapper>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </DarkThemeWrapper>
    </QueryClientProvider>
  </WagmiConfig>
);
