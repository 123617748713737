import React from 'react';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

interface JazziconAvatarProps {
  address: string;
  diameter: number;
}

const JazziconAvatar: React.FC<JazziconAvatarProps> = ({ address, diameter }) => {
  const seed = jsNumberForAddress(address);

  return (
    <div style={{ borderRadius: '50%', overflow: 'hidden', width: diameter, height: diameter }}>
      <Jazzicon diameter={diameter} seed={seed} />
    </div>
  );
};

export default JazziconAvatar;
