import React from 'react';
import { AvatarComponent } from '@rainbow-me/rainbowkit';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

// export const generateColorFromAddress = (address: string) => {
//   let hash = 0;
//   for (let i = 0; i < address.length; i++) {
//     hash = address.charCodeAt(i) + ((hash << 5) - hash);
//   }
//   const color = `#${((hash >> 24) & 0xff).toString(16)}${((hash >> 16) & 0xff).toString(16)}${((hash >> 8) & 0xff).toString(16)}`.slice(0, 7);
//   return color;
// };

const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
  // const color = generateColorFromAddress(address);
  const seed = jsNumberForAddress(address);

  return ensImage ? (
    <img
      src={ensImage}
      width={size}
      height={size}
      style={{ borderRadius: '50%' }}
      alt="ENS Avatar"
    />
  ) : (
    <div
      style={{
        borderRadius: '50%',
        height: size,
        width: size,
        overflow: 'hidden',
      }}
    >
      <Jazzicon diameter={size} seed={seed} />
    </div>
  );
};

export default CustomAvatar;
